'use client';
import {ChangeEvent} from "react";
import {usePathname, useSearchParams} from 'next/navigation';
import {languageNames} from "@/i18n";
import {useTranslation} from "react-i18next";

export function LanguageSelector() {
    const {i18n} = useTranslation();
    const currentLocale = i18n.resolvedLanguage;
    const currentQuery = useSearchParams().toString();
    const currentPathname = usePathname() + (currentQuery ? `?${currentQuery}` : '');

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newLocale = e.target.value;
        i18n.changeLanguage(newLocale).finally();
        const url = currentPathname.replace(`/${currentLocale}`, `/${newLocale}`);
        history.pushState({}, "", url);
    };

    return (
        <select name="locale" onChange={handleChange} value={currentLocale}
                className="
                tw-w-full tw-appearance-none tw-bg-white tw-block tw-text-[#212529] tw-leading-6
                tw-bg-no-repeat tw-bg-[length:16px_12px] tw-bg-[right_0.75rem_center] tw-bg-[url('/images/select.svg')]
                tw-border tw-border-[#dee2e6] tw-rounded-md
                tw-py-1.5 tw-pr-9 tw-pl-3
                "
        >
            {Object.entries(languageNames).map(([value, label], index) => (
                <option value={value} key={index}>{label}</option>
            ))}
        </select>
    );
}
