import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

type Props = {
    children: ReactNode;
    className?: string;
};

export function CardTitle({children, className = ''}: Props) {
    return (
        <div className={twMerge("tw-font-semibold tw-leading-6 tw-gunmetal-700 tw-mb-4", className)}>{children}</div>
    );
}
