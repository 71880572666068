import { cn } from '@/utils/cn';

export function SupermarketIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.9994 9.5V15.5C16.9994 15.9028 16.8536 16.2535 16.5619 16.5521C16.2703 16.8507 15.9161 17 15.4994 17H4.49942C4.08276 17 3.72859 16.8507 3.43692 16.5521C3.14526 16.2535 2.99942 15.9028 2.99942 15.5V9.47917C2.54109 9.15972 2.2529 8.75 2.13484 8.25C2.01678 7.75 2.02026 7.22222 2.14526 6.66667L3.10359 4C3.17303 3.66667 3.33276 3.41667 3.58276 3.25C3.83276 3.08333 4.13831 3 4.49942 3H15.4994C15.8605 3 16.1626 3.07639 16.4057 3.22917C16.6487 3.38194 16.8119 3.63889 16.8953 4L17.8536 6.66667C17.9786 7.22222 17.9786 7.75 17.8536 8.25C17.7286 8.75 17.4439 9.16667 16.9994 9.5ZM11.8328 8.5C12.1244 8.5 12.371 8.40278 12.5723 8.20833C12.7737 8.01389 12.8605 7.77778 12.8328 7.5L12.3328 4.5H10.8328V7.5C10.8328 7.77778 10.93 8.01389 11.1244 8.20833C11.3189 8.40278 11.555 8.5 11.8328 8.5ZM8.16609 8.5C8.44387 8.5 8.67998 8.40278 8.87442 8.20833C9.06887 8.01389 9.16609 7.77778 9.16609 7.5V4.5H7.66609L7.16609 7.5C7.13831 7.77778 7.22512 8.01389 7.42651 8.20833C7.6279 8.40278 7.87442 8.5 8.16609 8.5ZM4.49942 8.5C4.74942 8.5 4.96817 8.42361 5.15567 8.27083C5.34317 8.11806 5.45081 7.92361 5.47859 7.6875L5.99942 4.5H4.49942L3.56192 7.16667C3.45081 7.48611 3.49248 7.78819 3.68692 8.07292C3.88137 8.35764 4.1522 8.5 4.49942 8.5ZM15.4994 8.5C15.8466 8.5 16.121 8.35764 16.3223 8.07292C16.5237 7.78819 16.5689 7.48611 16.4578 7.16667L15.4994 4.5H13.9994L14.5203 7.6875C14.548 7.92361 14.6591 8.11806 14.8536 8.27083C15.048 8.42361 15.2633 8.5 15.4994 8.5Z" />
    </svg>
  );
}
