import {twMerge} from "@/tailwind-merge";

export function TelegramIcon({className = ''}: { className?: string }) {
    return (
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={twMerge("tw-fill-current tw-inline-block", className)}
        >
            <path
                d="M19.2949 4.02214C19.2949 4.02214 21.1451 3.30071 20.9909 5.05275C20.9395 5.77419 20.477 8.29919 20.1172 11.0303L18.8838 19.1207C18.8838 19.1207 18.781 20.3059 17.8559 20.512C16.9308 20.7181 15.5432 19.7906 15.2862 19.5844C15.0807 19.4299 11.4318 17.111 10.147 15.9773C9.7872 15.6681 9.37605 15.0497 10.1983 14.3283L15.5946 9.17524C16.2113 8.55686 16.8281 7.114 14.2584 8.86605L7.06339 13.7615C7.06339 13.7615 6.2411 14.2768 4.69934 13.813L1.35877 12.7824C1.35877 12.7824 0.125338 12.0094 2.23245 11.2364C7.37177 8.81448 13.6931 6.341 19.2949 4.02214Z"
            />
        </svg>
    );
}
