export function TelegramFullIcon({className = ''}: { className?: string }) {
    return (
        <svg className={className} viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2079_3267)">
                <path
                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                    fill="url(#paint0_linear_2079_3267)"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.62126 7.91547C5.95342 6.89938 7.50856 6.22952 8.28668 5.90587C10.5084 4.9818 10.97 4.82128 11.2709 4.81597C11.3371 4.81481 11.4851 4.83121 11.5809 4.90899C11.6618 4.97466 11.6841 5.06337 11.6948 5.12564C11.7054 5.1879 11.7187 5.32974 11.7081 5.44057C11.5877 6.70556 11.0668 9.77535 10.8018 11.1922C10.6896 11.7917 10.4688 11.9927 10.2551 12.0123C9.79048 12.0551 9.4377 11.7053 8.98774 11.4104C8.28363 10.9488 7.88586 10.6615 7.2024 10.2111C6.41255 9.69062 6.92458 9.40454 7.37471 8.93701C7.49252 8.81466 9.53944 6.95282 9.57906 6.78392C9.58402 6.7628 9.58861 6.68406 9.54184 6.64248C9.49506 6.60091 9.42602 6.61513 9.3762 6.62643C9.30558 6.64246 8.18077 7.38592 6.00175 8.85682C5.68248 9.07606 5.39329 9.18288 5.13419 9.17728C4.84855 9.17111 4.29909 9.01577 3.89062 8.883C3.38962 8.72014 2.99143 8.63404 3.02611 8.35746C3.04417 8.2134 3.24255 8.06607 3.62126 7.91547Z"
                      fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_2079_3267" x1="800" y1="0" x2="800" y2="1588.13"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2AABEE"/>
                    <stop offset="1" stopColor="#229ED9"/>
                </linearGradient>
                <clipPath id="clip0_2079_3267">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}
