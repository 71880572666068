import { twMerge } from '@/tailwind-merge';

export function PersonIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge('tw-inline-block', className)}
    >
      <path d="M10 9C9.16667 9 8.45833 8.70833 7.875 8.125C7.29167 7.54167 7 6.83333 7 6C7 5.16667 7.29167 4.45833 7.875 3.875C8.45833 3.29167 9.16667 3 10 3C10.8333 3 11.5417 3.29167 12.125 3.875C12.7083 4.45833 13 5.16667 13 6C13 6.83333 12.7083 7.54167 12.125 8.125C11.5417 8.70833 10.8333 9 10 9ZM4 13.5V13C4 12.6806 4.08681 12.3785 4.26042 12.0938C4.43403 11.809 4.67361 11.5694 4.97917 11.375C5.74306 10.9306 6.55208 10.5903 7.40625 10.3542C8.26042 10.1181 9.125 10 10 10C10.875 10 11.7396 10.1181 12.5938 10.3542C13.4479 10.5903 14.2569 10.9306 15.0208 11.375C15.3264 11.5556 15.566 11.7917 15.7396 12.0833C15.9132 12.375 16 12.6806 16 13V13.5C16 13.9167 15.8542 14.2708 15.5625 14.5625C15.2708 14.8542 14.9167 15 14.5 15H5.5C5.08333 15 4.72917 14.8542 4.4375 14.5625C4.14583 14.2708 4 13.9167 4 13.5Z" />
    </svg>
  );
}
