import { Agent } from '@/types';

export function agentTelegramLink(agent?: Agent): string {
  if (!agent) {
    return '#';
  }
  if (agent.telegram) {
    return `https://t.me/${agent.telegram}`;
  }
  if (agent.phone) {
    return `https://t.me/${agent.phone}`;
  }
  return '#';
}
