import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

type Params = {
  children: ReactNode;
  className?: string;
  style?: object;
  id?: string;
};

export function Card({children, className = '', style = {}, id = ''}: Params) {
  const props: Record<string, unknown> = {};
  if (Object.keys(style).length) {
    props.style = style;
  }
  if (id) {
    props.id = id;
  }

  return (
    <div className={twMerge(
      "tw-bg-white tw-w-full tw-px-4 tw-py-5 tw-shadow-[0_3px_2px_0_rgba(0,0,0,0.04),0_3px_16px_0_rgba(0,0,0,0.06)] tw-rounded-[0.625rem] tw-border-solid tw-border tw-border-[#e3e3e3] tw-flex tw-flex-col tw-mb-2",
      className
    )} {...props}>
      {children}
    </div>
  );
}
