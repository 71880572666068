import {ReactNode} from "react";
import {Property} from "./PropertyType";
import {LandAreaUnitTypes} from "./values";
import {ParseKeys, TFunction} from "i18next";

export function camelToSnakeCase(str: string): string {
    let result = str.replace('ID', 'Id');
    result = result[0].toLowerCase() + result.substring(1);
    result = result.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    return result;
}

export function snakeCaseToCamel(str: string): string {
    let result = str.replace('_id', 'ID');
    result = result.replace(/(_[a-z])/g, letter => `${letter[1].toUpperCase()}`);
    result = result[0].toUpperCase() + result.substring(1);
    return result;
}

export const UNSPECIFIED = '__unspecified__';
export const UNSPECIFIED_KEY = 'property.unspecified';

export const Unspecified = {
    lookup: (value: string | undefined, lookupTable: Record<string, ParseKeys>): ParseKeys => {
        if (value === undefined || lookupTable[value] === undefined) {
            return UNSPECIFIED_KEY;
        }

        return lookupTable[value];
    },
    boolean: (value: boolean | undefined | null, trueValue: ParseKeys | ReactNode, falseValue: ParseKeys | ReactNode, t: TFunction): string | ReactNode => {
        if (value === true) {
            return trueValue;
        } else if (value === false) {
            return falseValue;
        }
        return t(UNSPECIFIED_KEY);
    },
    booleanString: (value: boolean | undefined | null, trueValue: ParseKeys, falseValue: ParseKeys): ParseKeys => {
        if (value === true) {
            return trueValue;
        } else if (value === false) {
            return falseValue;
        }
        return UNSPECIFIED_KEY;
    },
    booleanLookup: (value: boolean | undefined | null, lookupTable: Record<string, ParseKeys>): ParseKeys => {
        return Unspecified.booleanString(value, lookupTable['1'], lookupTable['0']);
    },
    value: <T>(value: undefined | null | T, emptyValueAsUndefined = false): string | T => {
        if (value === undefined || value === null || value === '') {
            return UNSPECIFIED;
        }
        if (emptyValueAsUndefined && !value) {
            return UNSPECIFIED;
        }
        return value;
    },
    valueTranslated: <T>(value: undefined | null | T, t: TFunction, emptyValueAsUndefined = false): string | T => {
        if (value === undefined || value === null || value === '') {
            return t(UNSPECIFIED_KEY);
        }
        if (emptyValueAsUndefined && !value) {
            return t(UNSPECIFIED_KEY);
        }
        return value;
    },
    valueWithSuffix: <T>(value: undefined | null | T, unit: ParseKeys, t: TFunction, emptyValueAsUndefined = false): string => {
        if (value === undefined || value === null) {
            return t(UNSPECIFIED_KEY);
        }
        if (emptyValueAsUndefined && !value) {
            return t(UNSPECIFIED_KEY);
        }
        return t(unit, {value});
    },
    integerWithSuffix: (value: undefined | null | string | number, unit: ParseKeys, t: TFunction, emptyValueAsUndefined: boolean): string => {
        if (value === undefined || value === null) {
            return t(UNSPECIFIED_KEY);
        }
        if (emptyValueAsUndefined && !value) {
            return t(UNSPECIFIED_KEY);
        }
        let count: number;
        if (typeof value === 'string') {
            if (value.indexOf('.')) {
                count = parseFloat(value);
            } else {
                count = parseInt(value);
            }
        } else {
            count = value;
        }
        return t(unit, {count});
    }
};


export function levelName(value: number | undefined | string, t: TFunction): string {
    let result: string = '';
    if (value === undefined || value === 0) {
        return UNSPECIFIED;
    }
    switch (value.toString()) {
        case '-2':
            result = t('property.level-2');
            break;
        case '-1':
            result = t('property.level-1');
            break;
        default:
            result = value.toString();
            break;
    }
    return result;
}

export function landArea(property: Property, t: TFunction): string {
    const areaUnits = Object.fromEntries(Object.entries(LandAreaUnitTypes).map(([v, d]) => [v, d.short]));
    const areaUnit = Unspecified.lookup(property.LandAreaUnit, areaUnits);

    const area = Unspecified.value(property.Area, true);
    const isUndefined = area === UNSPECIFIED || areaUnit === UNSPECIFIED_KEY;
    return isUndefined ? t(UNSPECIFIED_KEY) : `${area} ${t(areaUnit)}`;
}

export function levelWithTotal(level: number | undefined | string, levelsTotal: number | undefined | string, t: TFunction) {
    const levelValue = levelName(level, t);
    const levelsTotalValue = Unspecified.value(levelsTotal);
    const isSpecified = levelValue !== UNSPECIFIED && levelsTotalValue !== UNSPECIFIED;
    if (isSpecified) {
        return t('property.levelWithTotal', {level: levelValue, total: levelsTotalValue});
    }
    return t(UNSPECIFIED_KEY);
}
