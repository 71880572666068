export function Spinner() {
    return (
        <div role="status" className="tw-absolute tw-m-auto tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-h-10 tw-w-20">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="tw-animate-spin"
            >
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M20 5C11.6594 5 5 11.6594 5 20C5 28.3406 11.6594 35 20 35C28.3406 35 35 28.3406 35 20C35 11.6594 28.3406 5 20 5ZM0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
                      fill="#D5DDFF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M35 20C35 11.7157 28.2843 5 20 5V0C31.0457 0 40 8.9543 40 20H35Z" fill="#2F56FF"/>
            </svg>
            <span className="tw-sr-only">Loading...</span>
        </div>
    );
}
