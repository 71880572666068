import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

export function StickyFooter({children, className = ''}: { children: ReactNode; className?: string }) {
    /**
     *   box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.1);
     *   flex-wrap: nowrap;
     *   justify-content: flex-start;
     */
    return (
        <div
            className={
                twMerge(
                    "tw-min-h-20 tw-bg-white tw-fixed tw-right-0 tw-left-0 tw-bottom-0 tw-z-50 tw-p-0 tw-mb-0 tw-shadow-[0_-2px_2px_0_rgba(0,0,0,0.1)]",
                    className
                )
            }>
            {children}
        </div>
    );
}
