import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

type Props = {
    title: string;
    children: ReactNode;
    className?: string;
};
export function ListItem({title, children, className = ''}: Props) {
    return (
        <div className={twMerge("tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-justify-around tw-w-full list-item-container", className)}>
            <div className="tw-w-3/5 tw-text-sm tw-font-medium tw-leading-5 tw-text-gunmetal-500 tw-p-2 tw-pl-0">{title}</div>
            <div className="tw-w-2/5 tw-text-gunmetal-700 tw-p-2 tw-pr-0">{children}</div>
        </div>
    );
}
