/// See https://fonts.google.com/icons?icon.set=Material+Symbols&selected=Material+Symbols+Outlined:phone_in_talk:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=phone
import Dashboard from './dashboard_FILL0_wght300_GRAD0_opsz24.svg';
import Delete from './delete_FILL1_wght300_GRAD0_opsz24.svg';
import Domain from './domain_FILL0_wght300_GRAD0_opsz24.svg';
import GarageHome from './garage_home_FILL0_wght300_GRAD0_opsz24.svg';
import GridView from './grid_view_FILL0_wght300_GRAD0_opsz24.svg';
import Group from './group_FILL0_wght300_GRAD0_opsz24.svg';
import Home from './home_FILL0_wght300_GRAD0_opsz24.svg';
import MeetingRoom from './meeting_room_FILL0_wght300_GRAD0_opsz24.svg';
import MovingMinistry from './moving_ministry_FILL0_wght300_GRAD0_opsz24_edited.svg';
import Psychiatry from './psychiatry_FILL0_wght300_GRAD0_opsz24.svg';
import SelectAll from './select_all_FILL0_wght300_GRAD0_opsz24.svg';
import Stairs from './stairs_FILL0_wght300_GRAD0_opsz24.svg';
import Star from './icon_sulafat.svg';
import Invalid from './icon_invalid_red_circle.svg';
import Note from './icon_note_grey_circle.svg';
import AddPhoto from './icon_add_photo.svg';
import Search from './icon_search.svg';
import Selected from './icon_selected.svg';
import Check from './icon_check_green_circle.svg';
import Share from './icon_share.svg';
import Logo from './logo.svg';
import Menu from './icon_menu.svg';
import NoEntry from './icon_no_entry.svg';

export const Icons = {
    Dashboard,
    Delete,
    Domain,
    GarageHome,
    GridView,
    Group,
    Home,
    MeetingRoom,
    MovingMinistry,
    Psychiatry,
    SelectAll,
    Stairs,
    Star,
    Invalid,
    Note,
    AddPhoto,
    Search,
    Selected,
    Check,
    Share,
    Logo,
    Menu,
    NoEntry,
};
