'use client';
import {ReactNode, useEffect, useState} from "react";
import {createPortal} from "react-dom";

type Props = {
    children: ReactNode;
};

export function HeaderAction({children}: Props){
    const [headerActions, setHeaderActions] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setHeaderActions(document.getElementById('app-header-actions'));
    }, [setHeaderActions]);

    if (headerActions) {
        return createPortal(children,headerActions);
    }
    return null;
}
