export function InfoIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={`tw-inline-block ${className}`}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.99658 11.2001C8.16559 11.2001 8.30843 11.1426 8.4251 11.0276C8.54176 10.9126 8.6001 10.7701 8.6001 10.6001V7.8001C8.6001 7.6301 8.54294 7.4876 8.42861 7.3726C8.31428 7.2576 8.17261 7.2001 8.00361 7.2001C7.8346 7.2001 7.69176 7.2576 7.5751 7.3726C7.45843 7.4876 7.4001 7.6301 7.4001 7.8001V10.6001C7.4001 10.7701 7.45726 10.9126 7.57158 11.0276C7.68591 11.1426 7.82758 11.2001 7.99658 11.2001ZM7.99658 6.0001C8.16559 6.0001 8.30843 5.94294 8.4251 5.82861C8.54176 5.71428 8.6001 5.57261 8.6001 5.40361C8.6001 5.2346 8.54294 5.09176 8.42861 4.9751C8.31428 4.85843 8.17261 4.8001 8.00361 4.8001C7.8346 4.8001 7.69176 4.85726 7.5751 4.97158C7.45843 5.08591 7.4001 5.22758 7.4001 5.39658C7.4001 5.56559 7.45726 5.70843 7.57158 5.8251C7.68591 5.94176 7.82758 6.0001 7.99658 6.0001ZM8.0047 14.4001C7.12385 14.4001 6.29454 14.2334 5.51676 13.9001C4.73899 13.5668 4.05843 13.1084 3.4751 12.5251C2.89176 11.9418 2.43343 11.2614 2.1001 10.4841C1.76676 9.70681 1.6001 8.87625 1.6001 7.99246C1.6001 7.10866 1.76676 6.28065 2.1001 5.50843C2.43343 4.73621 2.89176 4.05843 3.4751 3.4751C4.05843 2.89176 4.73875 2.43343 5.51606 2.1001C6.29339 1.76676 7.12394 1.6001 8.00773 1.6001C8.89153 1.6001 9.71954 1.76676 10.4918 2.1001C11.264 2.43343 11.9418 2.89176 12.5251 3.4751C13.1084 4.05843 13.5668 4.73745 13.9001 5.51216C14.2334 6.28689 14.4001 7.11466 14.4001 7.9955C14.4001 8.87634 14.2334 9.70565 13.9001 10.4834C13.5668 11.2612 13.1084 11.9418 12.5251 12.5251C11.9418 13.1084 11.2627 13.5668 10.488 13.9001C9.71331 14.2334 8.88553 14.4001 8.0047 14.4001Z" />
    </svg>
  );
}
