'use client';
import {CONFIG} from "@/config";
import {twMerge} from "@/tailwind-merge";
import {buttonStyle} from "@/components/_primitives";
import {TelegramIcon} from "@/icons/TelegramIcon";
import {useTranslation} from "react-i18next";

type Props = {
    redirect: string;
    className?: string;
};

export function TelegramBotLogin({redirect, className}: Props) {
    const {t} = useTranslation();
    const command = btoa(`a=l&r=${redirect}`);
    const mergedClassName = twMerge(
        buttonStyle({color: 'primary'}),
        'tw-flex tw-flex-row tw-gap-2 tw-align-middle tw-no-underline tw-h-12 tw-py-3 tw-px-5 ' + (className || '')
    );

    // https://core.telegram.org/api/links#bot-links
    const tgLink = `tg://resolve?domain=${CONFIG.BOT_NAME}&start=${command}`;
    // const tmeLink = `https://t.me/${CONFIG.BOT_NAME}?start=${command}`;

    return (
        <div>
            <a href={tgLink} className={mergedClassName}>
                <TelegramIcon className="tw-w-8 tw-h-8 -tw-mt-1"/> {t('signIn.loginText')}
            </a>
            {/*<a href={tmeLink} className={mergedClassName}>*/}
            {/*    <TelegramIcon className="tw-w-8 tw-h-8 -tw-mt-1"/> {t('signIn.loginText')} (t.me)*/}
            {/*</a>*/}
        </div>
    );
}
