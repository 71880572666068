import {twMerge} from "@/tailwind-merge";

export function ExpandMoreMiniIcon({className = ''}: { className?: string }) {
    return (
        <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" className={twMerge("tw-inline-block", className)}>
            <path
                d="M10.4959 12.75C10.3959 12.75 10.3022 12.7327 10.2147 12.6979C10.1272 12.6632 10.0487 12.6111 9.97928 12.5417L6.02799 8.5904C5.87052 8.43292 5.79525 8.25349 5.8022 8.0521C5.80914 7.85071 5.889 7.67363 6.04178 7.52085C6.19456 7.36808 6.37164 7.29169 6.57303 7.29169C6.77442 7.29169 6.9515 7.36808 7.10428 7.52085L10.5001 10.9375L13.9168 7.52085C14.0696 7.36808 14.2466 7.29516 14.448 7.3021C14.6494 7.30905 14.8265 7.38891 14.9793 7.54169C15.1321 7.69446 15.2084 7.87155 15.2084 8.07294C15.2084 8.27433 15.1297 8.45337 14.9722 8.61006L11.0209 12.5417C10.9459 12.6111 10.8647 12.6632 10.7772 12.6979C10.6897 12.7327 10.5959 12.75 10.4959 12.75Z"
            />
        </svg>
    );
}
