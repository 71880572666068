import {PhoneIcon} from "@/icons";

type Props = {
    phone: string
};
export function CallButton({phone}: Props) {
    return (
        <a href={`tel://${phone}`} className="tw-bg-royal-blue-100 tw-rounded-lg tw-w-10 tw-h-10 tw-inline-block tw-p-2">
            <PhoneIcon className="tw-fill-royal-blue-600-brand tw-h-6 tw-w-6" />
        </a>
    );
}
