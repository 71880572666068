import {ReactNode} from "react";
import {cn} from "@/utils/cn";

export function Main({children, className}: { children: ReactNode; className?: string }) {
    return (
        <main className={cn("tw-pb-20", className)}>
            {children}
        </main>
    );
}
