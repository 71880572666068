import { cn } from '@/utils/cn';

export function CheckmarkIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <g id="20/outlined/checkmark">
        <path
          id="icon"
          d="M7.75012 13.1275L5.15137 10.5288C4.85905 10.2364 4.38535 10.2356 4.092 10.5269C3.79717 10.8196 3.79633 11.2962 4.09012 11.59L7.05284 14.5527C7.43794 14.9378 8.0623 14.9378 8.44739 14.5527L16.2212 6.77888C16.5133 6.48679 16.5133 6.01322 16.2212 5.72113C15.9293 5.42914 15.4559 5.42903 15.1637 5.72088L7.75012 13.1275Z"
        />
      </g>
    </svg>
  );
}
