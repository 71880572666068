import { cn } from '@/utils/cn';

export function ChevronLeftIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <g id="20/outlined/chevron-left">
        <path
          id="Vector"
          d="M9.125 9.99996L12.5417 13.4166C12.6944 13.5694 12.7708 13.7465 12.7708 13.9479C12.7708 14.1493 12.6944 14.3263 12.5417 14.4791C12.3889 14.6319 12.2118 14.7083 12.0104 14.7083C11.809 14.7083 11.6296 14.6295 11.4721 14.472L7.52083 10.5208C7.45139 10.4458 7.39931 10.3645 7.36458 10.277C7.32986 10.1895 7.3125 10.0958 7.3125 9.99579C7.3125 9.89579 7.32986 9.80204 7.36458 9.71454C7.39931 9.62704 7.45139 9.54857 7.52083 9.47913L11.4721 5.52788C11.6296 5.37038 11.809 5.29163 12.0104 5.29163C12.2118 5.29163 12.3889 5.36801 12.5417 5.52079C12.6944 5.67357 12.7708 5.85065 12.7708 6.05204C12.7708 6.25343 12.6944 6.43051 12.5417 6.58329L9.125 9.99996Z"
        />
      </g>
    </svg>
  );
}
