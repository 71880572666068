'use client';

import {useEffect, useRef} from "react";
import {CONFIG} from "@/config";
import {useTranslation} from "react-i18next";
import {defaultLanguage} from "@/i18n";

export function TelegramLogin({redirect, className}: { redirect: string; className?: string; }) {
    const {i18n} = useTranslation();
    const telegramWrapperRef = useRef<HTMLDivElement>(null);
    const lng = i18n.resolvedLanguage || defaultLanguage;

    useEffect(() => {
        const redirectUrl = new URLSearchParams({redirect}).toString();
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
        scriptElement.setAttribute('data-telegram-login', CONFIG.BOT_NAME);
        scriptElement.setAttribute('data-size', 'large');
        scriptElement.setAttribute('data-auth-url', CONFIG.BASE_URL + '/auth?' + redirectUrl);
        scriptElement.setAttribute('data-request-access', 'write');
        scriptElement.setAttribute('data-userpic', 'false');
        scriptElement.setAttribute('data-radius', '8');
        // if (telegramWrapperRef.current) {
        //     scriptElement.setAttribute('data-min-width', telegramWrapperRef.current.clientWidth + '');
        //     scriptElement.setAttribute('data-max-width', telegramWrapperRef.current.clientWidth + '');
        // }
        scriptElement.setAttribute('data-lang', lng === 'ro' ? 'ru' : lng);
        scriptElement.async = true;

        telegramWrapperRef.current?.replaceChildren(scriptElement);
    }, [telegramWrapperRef, redirect, lng]);

    return (
        <div ref={telegramWrapperRef} className={className}></div>
    );
}
