import {Property} from "./PropertyType";
import {
    AptBuildingTypes,
    HouseTypes,
    LandTypes,
    OffertTypes,
    OfficeTypes,
    OwnerTypes,
    ParkingTypes,
    PriceTypes,
    PropertyTypes,
    RenovationTypes
} from "./values";
import {Icons} from "@/media";
import {landArea, levelWithTotal, Unspecified} from "./transformers";
import {TFunction} from "i18next";

export type PropertyInfoEntry = {
    key: string;
    value: string | number;
    media: string;
};

export function getPropertyInfo(property: Property, t: TFunction, extended: boolean = false): PropertyInfoEntry[] {
    let result: PropertyInfoEntry[] = [];

    switch (property.Type) {
        case "APARTMENT":
            result = getApartmentInfo(property, t, extended);
            break;
        case "COMMERCIAL":
            result = getCommercialInfo(property, t, extended);
            break;
        case "HOUSE":
            result = getHouseInfo(property, t);
            break;
        case "LAND":
            result = getLandInfo(property, t);
            break;
        case "OFFICE":
            result = getOfficeInfo(property, t, extended);
            break;
        case "PARKING":
            result = getParkingInfo(property, t);
            break;
        case "WAREHOUSE":
            result = getWarehouseInfo(property, t, extended);
            break;
    }

    return result;
}

function getApartmentInfo(property: Property, t: TFunction, extended: boolean): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.apartment.buildingTypeLabel'),
        value: t(Unspecified.lookup(property.AptBuildingType, AptBuildingTypes)),
        media: Icons.Domain
    });
    if (extended) {
        result.push({
            key: t('property.apartment.renovationLabel'),
            value: t(Unspecified.lookup(property.Reparation, RenovationTypes)),
            media: Icons.Domain
        });
    }
    result.push({
        key: t('propertyTypes.APARTMENT'),
        value: Unspecified.integerWithSuffix(property.Rooms, 'property.roomsValue', t, true),
        media: Icons.MeetingRoom
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });

    const levelString = levelWithTotal(property.Level, property.LevelsTotal, t);
    if (extended) {
        result.push({
            key: t('property.apartment.levelLabel'),
            value: t('property.apartment.levelLabelWithValue', {value: levelString}),
            media: Icons.Stairs
        });
    } else {
        result.push({
            key: t('property.apartment.levelLabel'),
            value: levelString,
            media: Icons.Stairs
        });
    }

    return result;
}

function getCommercialInfo(property: Property, t: TFunction, extended: boolean): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.commercial.ownerLabel'),
        value: t(Unspecified.lookup(property.Owner, OwnerTypes)),
        media: Icons.Group
    });
    result.push({
        key: t('property.commercial.officeTypeLabel'),
        value: t(Unspecified.lookup(property.OfficeType, OfficeTypes)),
        media: Icons.MovingMinistry
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });
    if (extended) {
        const levelsTotal = Unspecified.value(property.LevelsTotal, true);
        result.push({
            key: t('property.commercial.levelsTotalShortLabel'),
            value: t('property.commercial.levelsTotalLabelWithValue', {value: levelsTotal}),
            media: Icons.Stairs
        });
    } else {
        const levelsTotal = Unspecified.valueTranslated(property.LevelsTotal, t, true);
        result.push({
            key: t('property.commercial.levelsTotalShortLabel'),
            value: levelsTotal,
            media: Icons.Stairs
        });
    }

    return result;
}

function getHouseInfo(property: Property, t: TFunction): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.house.houseTypeLabel'),
        value: t(Unspecified.lookup(property.HouseType, HouseTypes)),
        media: Icons.Home
    });
    result.push({
        key: t('propertyTypes.HOUSE'),
        value: Unspecified.integerWithSuffix(property.Rooms, 'property.roomsValue', t, true),
        media: Icons.MeetingRoom
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });
    result.push({
        key: t('property.landAreaLabel'),
        value: Unspecified.integerWithSuffix(property.HouseLandArea, 'property.areaInAr', t, true),
        media: Icons.Dashboard
    });

    return result;
}

function getLandInfo(property: Property, t: TFunction): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.land.landTypeLabel'),
        value: t(Unspecified.lookup(property.LandType, LandTypes)),
        media: Icons.SelectAll
    });

    result.push({
        key: t('property.land.landAreaLabel'),
        value: landArea(property, t),
        media: Icons.Dashboard
    });
    if (property.LandType !== 'BUILDING') {
        result.push({
            key: t('property.land.landQualityShortLabel'),
            value: Unspecified.valueWithSuffix(property.LandQuality, 'property.land.landQualityValue', t, true),
            media: Icons.Psychiatry
        });
    }

    return result;
}

function getOfficeInfo(property: Property, t: TFunction, extended: boolean): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.office.ownerLabel'),
        value: t(Unspecified.lookup(property.Owner, OwnerTypes)),
        media: Icons.Group
    });
    result.push({
        key: t('property.office.officeTypeLabel'),
        value: t(Unspecified.lookup(property.OfficeType, OfficeTypes)),
        media: Icons.MovingMinistry
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });
    if (extended) {
        const levelsTotal = Unspecified.value(property.LevelsTotal, true);
        result.push({
            key: t('property.house.levelsTotalShortLabel'),
            value: t('property.house.levelsTotalLabelWithValue', {value: levelsTotal}),
            media: Icons.Stairs
        });
    } else {
        const levelsTotal = Unspecified.valueTranslated(property.LevelsTotal, t, true);
        result.push({
            key: t('property.house.levelsTotalShortLabel'),
            value: levelsTotal,
            media: Icons.Stairs
        });
    }

    return result;
}

function getParkingInfo(property: Property, t: TFunction): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];

    result.push({
        key: t('property.parking.parkingTypeLabel'),
        value: t(Unspecified.lookup(property.ParkingType, ParkingTypes)),
        media: Icons.GarageHome
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });

    return result;
}

function getWarehouseInfo(property: Property, t: TFunction, extended: boolean): PropertyInfoEntry[] {
    const result: PropertyInfoEntry[] = [];


    result.push({
        key: t('property.warehouse.ownerLabel'),
        value: t(Unspecified.lookup(property.Owner, OwnerTypes)),
        media: Icons.Group
    });
    result.push({
        key: t('property.warehouse.officeTypeLabel'),
        value: t(Unspecified.lookup(property.OfficeType, OfficeTypes)),
        media: Icons.MovingMinistry
    });
    result.push({
        key: t('property.areaLabel'),
        value: Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true),
        media: Icons.GridView
    });

    if (extended) {
        const levelsTotal = Unspecified.value(property.LevelsTotal, true);
        result.push({
            key: t('property.warehouse.levelsTotalShortLabel'),
            value: t('property.warehouse.levelsTotalLabelWithValue', {value: levelsTotal}),
            media: Icons.Stairs
        });
    } else {
        const levelsTotal = Unspecified.valueTranslated(property.LevelsTotal, t, true);
        result.push({
            key: t('property.warehouse.levelsTotalShortLabel'),
            value: levelsTotal,
            media: Icons.Stairs
        });
    }

    return result;
}

function getOffertTypeSuffix(t: TFunction, offertType?: keyof typeof OffertTypes): string {
    let result = '';
    switch (offertType) {
        case 'SELL':
            result = t('property.forSell');
            break;
        case 'RENT':
            result = t('property.forRent');
            break;
    }

    return result;
}

export function getPropertyTitle(property: Property, t: TFunction): string {
    const result: string[] = [];

    switch (property.Type) {
        case 'APARTMENT': {
            let aptType = '';
            if (property.Type) {
                aptType = t(PropertyTypes[property.Type]);
            }
            const aptOfferType = getOffertTypeSuffix(t, property.OffertType);
            result.push(`${aptType} ${aptOfferType}`);

            if (property.Rooms) {
                result.push(Unspecified.integerWithSuffix(property.Rooms, 'property.roomsValue', t, true));
            }
            if (property.Area) {
                result.push(Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true));
            }
            break;
        }
        case 'HOUSE': {
            let houseType = '';
            if (property.HouseType) {
                houseType = t(HouseTypes[property.HouseType]);
            }
            const houseOfferType = getOffertTypeSuffix(t, property.OffertType);
            result.push(`${houseType} ${houseOfferType}`);

            if (property.Rooms) {
                result.push(Unspecified.integerWithSuffix(property.Rooms, 'property.roomsValue', t, true));
            }
            if (property.Area) {
                result.push(Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true));
            }
            break;
        }
        case 'OFFICE': {
            let officeType = '';
            if (property.Type) {
                officeType = t(PropertyTypes[property.Type]);
            }
            const officeOfferType = getOffertTypeSuffix(t, property.OffertType);
            result.push(`${officeType} ${officeOfferType}`);

            if (property.OfficeType === 'BUILDING') {
                result.push(t(Unspecified.lookup(property.OfficeType, OfficeTypes)));
            }
            if (property.Area) {
                result.push(Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true));
            }
            break;
        }
        case 'COMMERCIAL':
        case 'WAREHOUSE': {
            let commercialType = '';
            if (property.Type) {
                commercialType = t(PropertyTypes[property.Type]);
            }
            const commercialOfferType = getOffertTypeSuffix(t, property.OffertType);
            result.push(`${commercialType} ${commercialOfferType}`);

            if (property.OfficeType === 'BUILDING') {
                result.push(t(Unspecified.lookup(property.OfficeType, OfficeTypes)));
            }
            if (property.Area) {
                result.push(Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true));
            }
            break;
        }
        case 'LAND': {
            let landType = '';
            if (property.Type) {
                landType = t(PropertyTypes[property.Type]);
            }
            let landSubType = '';
            if (property.LandType) {
                landSubType = t(LandTypes[property.LandType]);
            }
            result.push(`${landType} ${landSubType}`);

            result.push(landArea(property, t));
            break;
        }
        case 'PARKING': {
            let parkingType = '';
            if (property.ParkingType) {
                parkingType = t(ParkingTypes[property.ParkingType]);
            }
            const parkingOfferType = getOffertTypeSuffix(t, property.OffertType);
            result.push(`${parkingType} ${parkingOfferType}`);

            if (property.Area) {
                result.push(Unspecified.valueWithSuffix(property.Area, 'property.areaInM2', t, true));
            }
            break;
        }
    }

    if (property.DistrictName) {
        result.push(property.DistrictName);
    }
    if (property.CityName) {
        result.push(property.CityName);
    }

    return result.join(', ');
}

export function propertyTotalPrice(price?: number, priceType?: keyof typeof PriceTypes, area?: number): number | undefined {
    if (!price) {
        return undefined;
    }
    if (priceType === 'M2') {
        if (!area) {
            return undefined;
        }
        return price * area;
    }
    return price;
}

export function commissionFee(price?: number, priceType?: keyof typeof PriceTypes, area?: number, fee?: number): number | undefined {
    const calculatedPrice = propertyTotalPrice(price, priceType, area);
    if (!calculatedPrice || !fee) {
        return undefined;
    }
    return Math.ceil(calculatedPrice * (fee / 100));
}
