'use client';
import {Container, StickyFooter, CallButton} from "@/components";
import {Agent} from "@/types";

type Props = { agent: Agent };

export function AgentContacts({agent}: Props) {
    return (
        <StickyFooter className="tw-bg-gunmetal-700">
            <Container className="tw-p-4 tw-text-stroke-gray tw-text-base tw-flex tw-justify-between tw-items-center">
                <div className="tw-grow ">
                    Agent: <span className="tw-text-white">{agent.name}</span><br/>
                    Telefon: <span className="tw-text-white">{agent.phone}</span>
                </div>
                <CallButton phone={agent.phone}/>
            </Container>
        </StickyFooter>
    );
}
