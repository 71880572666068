import { cn } from '@/utils/cn';

export function CheckmarkCircleIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M12.0008 4.80078C8.02638 4.80078 4.80078 8.02638 4.80078 12.0008C4.80078 15.9752 8.02638 19.2008 12.0008 19.2008C15.9752 19.2008 19.2008 15.9752 19.2008 12.0008C19.2008 8.02638 15.9752 4.80078 12.0008 4.80078ZM10.5608 15.6008L6.96078 12.0008L7.97598 10.9856L10.5608 13.5632L16.0256 8.09838L17.0408 9.12078L10.5608 15.6008Z" />
      </g>
    </svg>
  );
}
