import { cn } from '@/utils/cn';

export function ExclusiveIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 14.0034C1.0875 14.0034 0.734375 13.8565 0.440625 13.5628C0.146875 13.269 0 12.9159 0 12.5034V1.50342C0 1.09092 0.146875 0.737794 0.440625 0.444043C0.734375 0.150293 1.0875 0.00341797 1.5 0.00341797H12.5C12.9125 0.00341797 13.2656 0.150293 13.5594 0.444043C13.8531 0.737794 14 1.09092 14 1.50342V12.5034C14 12.9159 13.8531 13.269 13.5594 13.5628C13.2656 13.8565 12.9125 14.0034 12.5 14.0034H1.5ZM7.00271 8.87842L8.50563 9.79508C8.65465 9.87842 8.79514 9.87147 8.92708 9.77425C9.05903 9.67703 9.10417 9.54508 9.0625 9.37842L8.66667 7.67113L10.0179 6.51342C10.1449 6.39564 10.1844 6.25661 10.1365 6.09634C10.0884 5.93606 9.98042 5.84953 9.8125 5.83675L8.05437 5.69988L7.36312 4.07446C7.29715 3.91599 7.17861 3.83675 7.0075 3.83675C6.83625 3.83675 6.71757 3.91599 6.65146 4.07446L5.95646 5.70529L4.18937 5.84259C4.02021 5.85536 3.9116 5.94113 3.86354 6.09988C3.81563 6.25877 3.85514 6.39661 3.98208 6.51342L5.33333 7.67113L4.9375 9.37842C4.89583 9.54508 4.93979 9.67703 5.06938 9.77425C5.1991 9.87147 5.34264 9.87842 5.5 9.79508L7.00271 8.87842Z" />
    </svg>
  );
}
