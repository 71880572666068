import {ReactNode, MouseEvent} from "react";
import {twMerge} from "@/tailwind-merge";
import {tv} from "tailwind-variants";

export const buttonStyle = tv({
    base: 'tw-text-center tw-px-3 tw-py-0 tw-rounded-lg tw-min-h-10',
    variants: {
        color: {
            primary: 'tw-bg-[#0d6efd] hover:tw-bg-[#0b5ed7] tw-text-white',
            secondary: 'tw-bg-main-violet/[0.2] tw-text-main-violet',
            transparent: 'tw-bg-transparent hover:tw-bg-transparent tw-text-black',
            semiTransparent: 'tw-bg-white/[0.2] hover:tw-bg-white/[0.75] tw-text-black',
        },
    },
    defaultVariants: {
        color: 'primary'
    }
});

export type ButtonProps = {
    children: ReactNode;
    type?: 'button' | 'reset' | 'submit';
    color?: 'primary' | 'secondary' | 'transparent' | 'semiTransparent';
    className?: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    disabled?: boolean
};

export function Button(props: ButtonProps) {
    const {type, color, className, children, ...rest} = props;
    const buttonType = type || 'button';
    const variant = {color};
    const buttonClassName = twMerge(buttonStyle(variant), className || '');
    const buttonProps = {...rest, type: buttonType, className: buttonClassName};

    return (
        <button {...buttonProps}>
            {children}
        </button>
    );
}
