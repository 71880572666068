'use client';

import {ParseKeys} from "i18next";
import {useTranslation} from "react-i18next";

export function Error({message}: { message: ParseKeys }) {
    const {t} = useTranslation();
    return (
        <div className="tw-text-red-700">
            {t(message)}
        </div>
    );
}
