import { cn } from '@/utils/cn';

export function DoorBackIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <g id="18/outlined/door-back">
        <path
          id="Vector"
          d="M2.25 15.75V14.25H3.75V3.75C3.75 3.3375 3.89688 2.98438 4.19063 2.69063C4.48438 2.39687 4.8375 2.25 5.25 2.25H12.75C13.1625 2.25 13.5156 2.39687 13.8094 2.69063C14.1031 2.98438 14.25 3.3375 14.25 3.75V14.25H15.75V15.75H2.25ZM5.25 14.25H12.75V3.75H5.25V14.25ZM7.5 9.75C7.7125 9.75 7.89062 9.67813 8.03438 9.53438C8.17813 9.39063 8.25 9.2125 8.25 9C8.25 8.7875 8.17813 8.60938 8.03438 8.46563C7.89062 8.32188 7.7125 8.25 7.5 8.25C7.2875 8.25 7.10938 8.32188 6.96563 8.46563C6.82188 8.60938 6.75 8.7875 6.75 9C6.75 9.2125 6.82188 9.39063 6.96563 9.53438C7.10938 9.67813 7.2875 9.75 7.5 9.75Z"
        />
      </g>
    </svg>
  );
}
