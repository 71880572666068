import {ParseKeys} from "i18next";

export const OffertTypes = {
    "SELL": "offertTypes.SELL",
    "RENT": "offertTypes.RENT"
} as const;

export const PropertyTypes = {
    "APARTMENT": 'propertyTypes.APARTMENT',
    "HOUSE": 'propertyTypes.HOUSE',
    "OFFICE": 'propertyTypes.OFFICE',
    "COMMERCIAL": 'propertyTypes.COMMERCIAL',
    "WAREHOUSE": 'propertyTypes.WAREHOUSE',
    "LAND": 'propertyTypes.LAND',
    "PARKING": 'propertyTypes.PARKING',
} as const;

export type PropertyTypeValue = keyof typeof PropertyTypes;

export const AptBuildingTypes = {
    'NEW': 'apartmentBuildingTypes.NEW',
    'OLD': 'apartmentBuildingTypes.OLD'
} as const;

export const PriceTypes = {
    'TOTAL': 'priceTypes.TOTAL',
    'M2': 'priceTypes.M2'
} as const;

export const RenovationTypes = {
    'UNFINISHED': 'renovationTypes.UNFINISHED',
    'WHITE': 'renovationTypes.WHITE',
    'GRAY': 'renovationTypes.GRAY',
    'COSMETIC': 'renovationTypes.COSMETIC',
    'EURO': 'renovationTypes.EURO',
    'INDIVIDUAL': 'renovationTypes.INDIVIDUAL',
    'REQUIRED': 'renovationTypes.REQUIRED'
} as const;
export type RenovationTypeValue = keyof typeof RenovationTypes;


export const FurnitureTypes = {
    'NO': 'furnitureTypes.NO',
    'PART': 'furnitureTypes.PART',
    'FULL': 'furnitureTypes.FULL',
} as const;

export const AptParkingTypes = {
    'UNDERGROUND': 'apartmentParkingTypes.UNDERGROUND',
    'ONSTREET': 'apartmentParkingTypes.ONSTREET',
    'GARAGE': 'apartmentParkingTypes.GARAGE',
} as const;

export const AptKidsAllowedTypes = {
    '1': 'apartmentKidsAllowedTypes.yes',
    '0': 'apartmentKidsAllowedTypes.no'
} as const;

export const AptAnimalsAllowedTypes = {
    '1': 'apartmentAnimalsAllowedTypes.yes',
    '0': 'apartmentAnimalsAllowedTypes.no'
} as const;

export const CreditTypes = {
    1: 'creditTypes.yes',
    0: 'creditTypes.no'
} as const;

export const HeatingTypes = {
    'PRIVATE': 'heatingTypes.PRIVATE',
    'CENTRALIZED': 'heatingTypes.CENTRALIZED',
} as const;

export const HouseTypes = {
    'HOUSE': 'houseTypes.HOUSE',
    'TOWNHOUSE': 'houseTypes.TOWNHOUSE',
    'DUPLEX': 'houseTypes.DUPLEX',
} as const;

export const OwnerTypes = {
    'INDIVIDUAL': 'ownerTypes.INDIVIDUAL',
    'LEGAL': 'ownerTypes.LEGAL',
} as const;

export const OfficeTypes = {
    'LEVEL': 'officeTypes.LEVEL',
    'BUILDING': 'officeTypes.BUILDING',
} as const;

export const LandTypes = {
    'AGRO': 'landTypes.AGRO',
    'BUILDING': 'landTypes.BUILDING',
    'LAKE': 'landTypes.LAKE'
} as const;

export const ParkingTypes = {
    'UNDERGROUND': 'parkingTypes.UNDERGROUND',
    'ONSTREET': 'parkingTypes.ONSTREET',
    'GARAGE': 'parkingTypes.GARAGE',
} as const;

export const LandAreaUnitTypes = {
    'AR': {
        label: 'landAreaUnitTypes.arLabel',
        short: 'landAreaUnitTypes.arShortLabel',
    },
    'HA': {
        label: 'landAreaUnitTypes.haLabel',
        short: 'landAreaUnitTypes.haShortLabel',
    }
} as const;

export const ApartmentFloorOptions = {
    'not_parterre': 'apartmentFloorOptions.not_parterre',
    'not_last': 'apartmentFloorOptions.not_last',
    'last': 'apartmentFloorOptions.last',
} as const;

export const ApartmentConflictingFloorOptions = {
    last: ['not_last'],
    not_last: ['last']
};

export const FloorOptions = {
    'parterre': 'floorOptions.parterre',
    'not_plinth': 'floorOptions.not_plinth',
    'not_basement': 'floorOptions.not_basement',
} as const;


function mapRenovationOptionsToTranslations(values: string[]): Record<string, ParseKeys> {
    return values.reduce((result: Record<string, ParseKeys>, value) => {
        result[value] = 'renovationTypes.' + value;
        return result;
    }, {} as Record<string, ParseKeys>);
}

export function apartmentRenovationOptions(offert_type: undefined | string, apt_building_type: undefined | string) {
    let renovationOptions: RenovationTypeValue[] = ['COSMETIC', 'EURO', 'INDIVIDUAL'];

    if (offert_type === 'SELL' && apt_building_type === 'NEW') {
        renovationOptions = ['WHITE', 'GRAY', ...renovationOptions];
    }
    if (apt_building_type === 'OLD') {
        renovationOptions = [...renovationOptions, 'REQUIRED'];
    }

    return mapRenovationOptionsToTranslations(renovationOptions);
}

export function houseRenovationOptions(offert_type: undefined | string) {
    let renovationOptions: RenovationTypeValue[] = ['COSMETIC', 'EURO', 'INDIVIDUAL'];

    if (offert_type === 'SELL') {
        renovationOptions = [
            'UNFINISHED',
            'WHITE',
            'GRAY',
            ...renovationOptions,
        ];
    }

    return mapRenovationOptionsToTranslations(renovationOptions);
}

export function officeRenovationOptions() {
    const renovationOptions: RenovationTypeValue[] = ['WHITE', 'GRAY', 'COSMETIC', 'EURO', 'INDIVIDUAL'];

    return mapRenovationOptionsToTranslations(renovationOptions);
}

export function landOtherOptions(land_type: string | undefined): Record<string, ParseKeys> {
    if (land_type === 'AGRO') {
        return {
            'land_garden': 'property.land.gardenLabel',
            'land_vineyards': 'property.land.vineyardsLabel',
        };
    }
    if (land_type === 'LAKE') {
        return {
            'land_forest': 'property.land.forestLabel',
            'land_cottages': 'property.land.cottagesLabel',
            'land_pavilion': 'property.land.pavilionLabel',
        };
    }
    return {};
}

export function landCommunications(): Record<string, ParseKeys> {
    return {
        land_electricity: 'property.land.electricityLabel',
        land_gas: 'property.land.gasLabel',
        land_canalization: 'property.land.canalizationLabel',
        land_water: 'property.land.waterLabel',
    };
}

export const LoanIncome = {
    'SALARY_MOLDOVA': 'loanIncomeOptions.salaryMoldova',
    'OTHER_MOLDOVA': 'loanIncomeOptions.otherMoldova',
    'SALARY_FOREIGN': 'loanIncomeOptions.salaryForeign',
} as const;
