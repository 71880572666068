'use client';

import {useEffect} from "react";
import {useLanguage, hashRedirect} from "@/utils";

export function Redirect() {
    const lang = useLanguage();

    useEffect(() => {
        const redirect = hashRedirect(window.location.hash, lang);
        if (redirect) {
            window.location.href = redirect;
        }
    }, [lang]);

    return null;
}
