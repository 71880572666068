import { cn } from '@/utils/cn';

export function SearchIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block tw-fill-none', className)}
    >
      <path
        d="M15.4792 15.4935L20.25 20.25M17.5 10.625C17.5 14.422 14.422 17.5 10.625 17.5C6.82804 17.5 3.75 14.422 3.75 10.625C3.75 6.82804 6.82804 3.75 10.625 3.75C14.422 3.75 17.5 6.82804 17.5 10.625Z"
        stroke="#2F56FF"
        strokeWidth="1.837"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
