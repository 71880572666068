import {CreateType, PropertyErrors} from "@/utils/CreateType";

export function validateProperty(values: CreateType): PropertyErrors {
    const errors: PropertyErrors = {};

    if (['', '0'].includes(values.area || '')) {
        errors.area = 'Required';
    }
    if (['', '0'].includes(values.price || '')) {
        errors.price = 'Required';
    }
    if (values.location === '' || values.location === undefined) {
        errors.location = 'Required';
    }
    if (values.fee === '' || values.fee === undefined) {
        errors.fee = 'Required';
    }

    switch (values.type) {
        case 'APARTMENT':
            if (values.rooms === '' || values.rooms === undefined) {
                errors.rooms = 'Required';
            }
            if (values.reparation === '' || values.reparation === undefined) {
                errors.reparation = 'Required';
            }
            if (['', '0'].includes(values.level || '')) {
                errors.level = 'Required';
            }
            if (['', '0'].includes(values.levels_total || '')) {
                errors.levels_total = 'Required';
            }
            break;
        case 'HOUSE':
            if (values.house_type === '' || values.house_type === undefined) {
                errors.house_type = 'Required';
            }
            if (values.rooms === '' || values.rooms === undefined) {
                errors.rooms = 'Required';
            }
            if (values.reparation === '' || values.reparation === undefined) {
                errors.reparation = 'Required';
            }
            if (['', '0'].includes(values.house_land_area || '')) {
                errors.house_land_area = 'Required';
            }
            break;
        case 'OFFICE':
            if (values.reparation === '' || values.reparation === undefined) {
                errors.reparation = 'Required';
            }
            if (values.owner === '' || values.owner === undefined) {
                errors.owner = 'Required';
            }
            if (values.owner === 'LEGAL' && (values.owner_vat === '' || values.owner_vat === undefined)) {
                errors.owner_vat = 'Required';
            }
            if (values.office_type === '' || values.office_type === undefined) {
                errors.office_type = 'Required';
            }
            if (values.office_type === 'LEVEL' && ['', '0'].includes(values.level || '')) {
                errors.level = 'Required';
            }
            if (values.office_type === 'BUILDING' && ['', '0'].includes(values.house_land_area || '')) {
                errors.house_land_area = 'Required';
            }
            if (['', '0'].includes(values.levels_total || '')) {
                errors.levels_total = 'Required';
            }
            if (values.office_parking === '1' && (values.office_parking_lots === '' || values.office_parking_lots === undefined)) {
                errors.office_parking_lots = 'Required';
            }
            break;
        case 'COMMERCIAL':
            if (values.reparation === '' || values.reparation === undefined) {
                errors.reparation = 'Required';
            }
            if (values.owner === '' || values.owner === undefined) {
                errors.owner = 'Required';
            }
            if (values.owner === 'LEGAL' && (values.owner_vat === '' || values.owner_vat === undefined)) {
                errors.owner_vat = 'Required';
            }
            if (values.office_type === '' || values.office_type === undefined) {
                errors.office_type = 'Required';
            }
            if (values.office_type === 'LEVEL' && ['', '0'].includes(values.level || '')) {
                errors.level = 'Required';
            }
            if (values.office_type === 'BUILDING' && ['', '0'].includes(values.house_land_area || '')) {
                errors.house_land_area = 'Required';
            }
            if (['', '0'].includes(values.levels_total || '')) {
                errors.levels_total = 'Required';
            }
            break;
        case 'WAREHOUSE':
            if (values.owner === '' || values.owner === undefined) {
                errors.owner = 'Required';
            }
            if (values.owner === 'LEGAL' && (values.owner_vat === '' || values.owner_vat === undefined)) {
                errors.owner_vat = 'Required';
            }
            if (values.office_type === '' || values.office_type === undefined) {
                errors.office_type = 'Required';
            }
            if (values.office_type === 'LEVEL' && ['', '0'].includes(values.level || '')) {
                errors.level = 'Required';
            }
            if (values.office_type === 'BUILDING' && ['', '0'].includes(values.house_land_area || '')) {
                errors.house_land_area = 'Required';
            }
            if (['', '0'].includes(values.levels_total || '')) {
                errors.levels_total = 'Required';
            }
            break;
        case 'LAND':
            if (values.land_area_unit === '' || values.land_area_unit === undefined) {
                errors.land_area_unit = 'Required';
            }
            break;
        case 'PARKING':
            if (values.parking_type === '' || values.parking_type === undefined) {
                errors.parking_type = 'Required';
            }
            break;
    }

    return errors;
}
