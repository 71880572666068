import { cn } from '@/utils/cn';

export function PercentIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <g id="18/outlined/percent">
        <path
          id="Vector"
          d="M5.62305 8.25C4.89805 8.25 4.2793 7.99375 3.7668 7.48125C3.2543 6.96875 2.99805 6.35 2.99805 5.625C2.99805 4.9 3.2543 4.28125 3.7668 3.76875C4.2793 3.25625 4.89805 3 5.62305 3C6.34805 3 6.9668 3.25625 7.4793 3.76875C7.9918 4.28125 8.24805 4.9 8.24805 5.625C8.24805 6.35 7.9918 6.96875 7.4793 7.48125C6.9668 7.99375 6.34805 8.25 5.62305 8.25ZM5.62305 6.75C5.93555 6.75 6.20117 6.64063 6.41992 6.42188C6.63867 6.20313 6.74805 5.9375 6.74805 5.625C6.74805 5.3125 6.63867 5.04688 6.41992 4.82813C6.20117 4.60938 5.93555 4.5 5.62305 4.5C5.31055 4.5 5.04492 4.60938 4.82617 4.82813C4.60742 5.04688 4.49805 5.3125 4.49805 5.625C4.49805 5.9375 4.60742 6.20313 4.82617 6.42188C5.04492 6.64063 5.31055 6.75 5.62305 6.75ZM12.373 15C11.648 15 11.0293 14.7438 10.5168 14.2313C10.0043 13.7188 9.74805 13.1 9.74805 12.375C9.74805 11.65 10.0043 11.0312 10.5168 10.5188C11.0293 10.0063 11.648 9.75 12.373 9.75C13.098 9.75 13.7168 10.0063 14.2293 10.5188C14.7418 11.0312 14.998 11.65 14.998 12.375C14.998 13.1 14.7418 13.7188 14.2293 14.2313C13.7168 14.7438 13.098 15 12.373 15ZM12.373 13.5C12.6855 13.5 12.9512 13.3906 13.1699 13.1719C13.3887 12.9531 13.498 12.6875 13.498 12.375C13.498 12.0625 13.3887 11.7969 13.1699 11.5781C12.9512 11.3594 12.6855 11.25 12.373 11.25C12.0605 11.25 11.7949 11.3594 11.5762 11.5781C11.3574 11.7969 11.248 12.0625 11.248 12.375C11.248 12.6875 11.3574 12.9531 11.5762 13.1719C11.7949 13.3906 12.0605 13.5 12.373 13.5ZM4.04805 15L2.99805 13.95L13.948 3L14.998 4.05L4.04805 15Z"
        />
      </g>
    </svg>
  );
}
