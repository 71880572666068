import {twMerge} from "@/tailwind-merge";

export function FlagIcon({className = ''}: { className?: string }) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={twMerge("fill-current tw-inline-block", className)}>
            <path
                d="M5.5 11V16.25C5.5 16.4625 5.42854 16.6406 5.28563 16.7844C5.14271 16.9281 4.96563 17 4.75438 17C4.54313 17 4.36458 16.9281 4.21875 16.7844C4.07292 16.6406 4 16.4625 4 16.25V3.75C4 3.5375 4.07188 3.35937 4.21563 3.21562C4.35938 3.07187 4.5375 3 4.75 3H10.4113C10.5954 3 10.7547 3.05271 10.8892 3.15813C11.0236 3.26368 11.1092 3.39847 11.1458 3.5625L11.5 5H15.25C15.4625 5 15.6406 5.07187 15.7844 5.21562C15.9281 5.35937 16 5.5375 16 5.75V12.25C16 12.4625 15.9281 12.6406 15.7844 12.7844C15.6406 12.9281 15.4625 13 15.25 13H11.5833C11.4089 13 11.2531 12.9473 11.1158 12.8419C10.9788 12.7363 10.8915 12.6015 10.8542 12.4375L10.5 11H5.5Z"
                fill="#475467"/>
        </svg>
    );
}