import { Lang } from '@/types';
import { formatDate, isDate } from 'date-fns';
import { ro, ru } from 'date-fns/locale';
import type { FlatNamespace, Resource } from 'i18next';
import { createInstance, i18n } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

const locales = { ro, ru };

export const defaultLanguage: Lang = 'ro';
export const languages: string[] = [defaultLanguage, 'ru'];

export const languageNames: Record<string, string> = {
  ro: 'RO',
  ru: 'RU',
};

export const defaultNS: FlatNamespace = 'web';

export default async function initTranslations(
  locale: string,
  namespaces: string[],
  i18nInstance?: i18n,
  resources?: Resource,
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../../public/locales/${namespace}.${language}.json`),
      ),
    );
  }

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: defaultLanguage,
    supportedLngs: languages,
    defaultNS: namespaces[0],
    fallbackNS: namespaces[0],
    ns: namespaces,
    preload: resources ? [] : languages,

    interpolation: {
      format: (value, format, lng) => {
        if (isDate(value)) {
          if (lng && format) {
            const locale = locales[lng as keyof typeof locales];
            return formatDate(value, format, { locale });
          }
        }

        return value;
      },
    },
  });

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t,
  };
}
