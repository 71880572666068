'use client';

import {ShareIcon} from "@/icons/ShareIcon";
import {Button} from "@/components/_primitives";
import {Toast, ToastContainer} from "react-bootstrap";
import {MouseEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {sendGTMEvent} from "@next/third-parties/google";

export function ShareLinkButton() {
    const {t} = useTranslation();
    const [showAlert, setShowAlert] = useState(false);

    const copyURL = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (typeof navigator.share === "function") {
            navigator.share({url: window.location.toString()}).finally();
        } else {
            navigator.clipboard.writeText(window.location.toString()).catch();
            setShowAlert(true);
        }
        sendGTMEvent({event: 'share_offer'});
    };

    return (
        <>
            <Button onClick={copyURL} color='transparent'>
                <ShareIcon className="tw-h-4 tw-w-4 tw-inline-block -tw-mt-1"/> {t('property.shareButtonLabel')}
            </Button>
            <ToastContainer position='top-center'>
                <Toast show={showAlert} bg='success' autohide={true} onClose={() => setShowAlert(false)}>
                    <Toast.Header closeButton={true}>
                        <span>{t('property.shareMessage')}</span>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
}
