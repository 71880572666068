export const MAX_PHOTOS: number = 10;
export const MAX_LETTER_DESCRIPTION: number = 2000;

export const CONFIG = {
    BOT_URL: process.env.NEXT_PUBLIC_BOT_URL || '',
    BOT_NAME: process.env.NEXT_PUBLIC_BOT_NAME || '',
    API_URL: process.env.NEXT_PUBLIC_API_URL || '',
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || '',
    GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '',
    GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
    YANDEX_METRIKA_ID: +(process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID || 0),
    POPULAR_LOCATIONS: [1, 2] // TODO(future): Build this array based on .env
};
