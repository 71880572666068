import { cn } from '@/utils/cn';

export function SpeedCameraIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.1458 12.2501L12.75 11.4584L15.375 9.50009L16.75 10.2918L14.1458 12.2501ZM4 16.0001V14.5011H7.75V10.0418L5.50604 8.74905C5.14451 8.53669 4.91076 8.23377 4.80479 7.8403C4.69882 7.44683 4.75132 7.06856 4.96229 6.70551L6.07938 4.75301C6.29035 4.38995 6.59375 4.15634 6.98958 4.05218C7.38542 3.94801 7.76458 4.00204 8.12708 4.21426L15.2708 8.33343L10.7083 11.7501L9.25 10.9168V14.4995C9.25 14.9121 9.10313 15.2654 8.80938 15.5593C8.51563 15.8531 8.1625 16.0001 7.75 16.0001H4Z" />
    </svg>
  );
}
