import {twMerge} from "@/tailwind-merge";

export function ErrorIcon({className = ''}: {className?: string}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={twMerge("tw-inline-block", className)}>
            <path
                d="M7.99658 11.2C8.16559 11.2 8.30843 11.1428 8.4251 11.0285C8.54176 10.9142 8.6001 10.7725 8.6001 10.6035C8.6001 10.4345 8.54294 10.2917 8.42861 10.175C8.31428 10.0583 8.17261 10 8.00361 10C7.8346 10 7.69176 10.0572 7.5751 10.1715C7.45843 10.2858 7.4001 10.4275 7.4001 10.5965C7.4001 10.7655 7.45726 10.9083 7.57158 11.025C7.68591 11.1417 7.82758 11.2 7.99658 11.2ZM7.99658 8.80001C8.16559 8.80001 8.30843 8.74251 8.4251 8.62751C8.54176 8.51251 8.6001 8.37001 8.6001 8.20001V5.40001C8.6001 5.23001 8.54294 5.08751 8.42861 4.97251C8.31428 4.85751 8.17261 4.80001 8.00361 4.80001C7.8346 4.80001 7.69176 4.85751 7.5751 4.97251C7.45843 5.08751 7.4001 5.23001 7.4001 5.40001V8.20001C7.4001 8.37001 7.45726 8.51251 7.57158 8.62751C7.68591 8.74251 7.82758 8.80001 7.99658 8.80001ZM8.0047 14.4C7.12385 14.4 6.29454 14.2333 5.51676 13.9C4.73899 13.5667 4.05843 13.1083 3.4751 12.525C2.89176 11.9417 2.43343 11.2614 2.1001 10.484C1.76676 9.70672 1.6001 8.87616 1.6001 7.99237C1.6001 7.10857 1.76676 6.28056 2.1001 5.50834C2.43343 4.73612 2.89176 4.05834 3.4751 3.47501C4.05843 2.89167 4.73875 2.43334 5.51606 2.10001C6.29339 1.76667 7.12394 1.60001 8.00773 1.60001C8.89153 1.60001 9.71954 1.76667 10.4918 2.10001C11.264 2.43334 11.9418 2.89167 12.5251 3.47501C13.1084 4.05834 13.5668 4.73736 13.9001 5.51207C14.2334 6.2868 14.4001 7.11457 14.4001 7.99541C14.4001 8.87625 14.2334 9.70556 13.9001 10.4833C13.5668 11.2611 13.1084 11.9417 12.5251 12.525C11.9418 13.1083 11.2627 13.5667 10.488 13.9C9.71331 14.2333 8.88553 14.4 8.0047 14.4Z"
                fill="#D92D20"/>
        </svg>
    );
}
