import { cn } from '@/utils/cn';

export function ChevronRightIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <g id="20/outlined/chevron-right">
        <path
          id="Vector"
          d="M10.8751 9.99996L7.45841 6.58329C7.30564 6.43051 7.22925 6.25343 7.22925 6.05204C7.22925 5.85065 7.30564 5.67357 7.45841 5.52079C7.61119 5.36801 7.78828 5.29163 7.98966 5.29163C8.19105 5.29163 8.3705 5.37038 8.528 5.52788L12.4792 9.47913C12.5487 9.55413 12.6008 9.63538 12.6355 9.72288C12.6702 9.81038 12.6876 9.90413 12.6876 10.0041C12.6876 10.1041 12.6702 10.1979 12.6355 10.2854C12.6008 10.3729 12.5487 10.4513 12.4792 10.5208L8.528 14.472C8.3705 14.6295 8.19453 14.7048 8.00008 14.6979C7.80564 14.6909 7.63203 14.6111 7.47925 14.4583C7.32647 14.3055 7.25008 14.1284 7.25008 13.927C7.25008 13.7257 7.32647 13.5486 7.47925 13.3958L10.8751 9.99996Z"
        />
      </g>
    </svg>
  );
}
