import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";
import {tv} from "tailwind-variants";

const buttonStyle = tv({
    base: 'tw-text-center tw-px-6 tw-py-3 tw-rounded-lg tw-no-underline tw-font-medium',
    variants: {
        color: {
            primary: 'tw-bg-royal-blue-600-brand tw-text-white',
            blue: 'tw-bg-royal-blue-500 tw-text-white',
        },
    },
    defaultVariants: {
        color: 'primary'
    }
});

type Props = {
    color?: 'primary' | 'blue';
    children: ReactNode;
    href: string;
    className?: string
};

export function LinkButton(props: Props) {
    const className = twMerge(buttonStyle({color: props.color || 'primary'}), props.className || '');

    return (
        <a href={props.href} className={className}>{props.children}</a>
    );
}