import {ReactNode} from "react";
import Image from "next/image";
import {Icons} from "@/media";
import {tv} from "tailwind-variants";

type Props = {
    children: ReactNode;
};

const noteStyle = tv({
    base: 'tw-text-xs tw-font-normal tw-flex tw-items-start tw-rounded-lg tw-p-3 tw-border tw-border-solid',
    variants: {
        color: {
            light: 'tw-text-[#667085] tw-bg-[#2f56ff0d] tw-border-[#2f56ff0d]',
        },
    },
    defaultVariants: {
        color: 'light'
    }
});

export function Note({children}: Props) {
    const noteClassName = noteStyle();

    return (
        <div className={noteClassName}>
            <Image src={Icons.Note} alt="" className="tw-mt-0.5 tw-mr-2"/>
            <div>{children}</div>
        </div>
    );
}
