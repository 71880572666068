export function hashRedirect(hash: string, lang: string): string | undefined {
    const oldUrl = hash;
    if (!oldUrl.length || oldUrl[0] !== '#') {
        return;
    }
    const url = oldUrl.substring(1);
    const query = new URLSearchParams(url.split('?')[1]);

    let redirect: string | undefined = undefined;
    if (url.includes('property?id=')) {
        const id = query.get('id');
        redirect = `/${lang}/property/${id}`;
    }
    if (url.includes('/add-sale')) {
        redirect = `/${lang}/property/create`;
    }
    if (url.includes('/add-request')) {
        redirect = `/${lang}/search`;
    }
    if (url.includes('/edit')) {
        const id = url.split('/')[3];
        redirect = `/${lang}/property/edit/${id}`;
    }
    return redirect;
}
