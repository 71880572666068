'use client';
import {Card} from "./Card";
import {useTranslation} from "react-i18next";
import {FlagIcon} from "@/icons/FlagIcon";

export function ReportIssueCard() {
    const {t} = useTranslation();

    return <Card className="tw-py-8">
        <div className="tw-mb-5">{t('issueReport.question')}</div>
        <div>
            <FlagIcon className="tw-w-5 tw-h-5 tw-fill-gunmetal-600 tw-mr-2"/>
            <a
                href="https://t.me/realoc_support"
                target="_blank"
                rel="noreferrer"
                className="tw-text-gunmetal-800 tw-underline"
            >
                {t('issueReport.linkLabel')}
            </a>
        </div>
    </Card>;
}
