import {cn} from "@/utils/cn";

export function AddHouse({className = ''}: { className?: string }) {
    return (
        <svg
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
            className={cn('tw-inline-block fill-current', className)}
        >
            <path
                d="M18 16.5V18.5C18 18.6333 18.05 18.75 18.15 18.85C18.25 18.95 18.3667 19 18.5 19C18.6333 19 18.75 18.95 18.85 18.85C18.95 18.75 19 18.6333 19 18.5V16.5H21C21.1333 16.5 21.25 16.45 21.35 16.35C21.45 16.25 21.5 16.1333 21.5 16C21.5 15.8667 21.45 15.75 21.35 15.65C21.25 15.55 21.1333 15.5 21 15.5H19V13.5C19 13.3667 18.95 13.25 18.85 13.15C18.75 13.05 18.6333 13 18.5 13C18.3667 13 18.25 13.05 18.15 13.15C18.05 13.25 18 13.3667 18 13.5V15.5H16C15.8667 15.5 15.75 15.55 15.65 15.65C15.55 15.75 15.5 15.8667 15.5 16C15.5 16.1333 15.55 16.25 15.65 16.35C15.75 16.45 15.8667 16.5 16 16.5H18ZM18.5 21C17.1167 21 15.9375 20.5125 14.9625 19.5375C13.9875 18.5625 13.5 17.3833 13.5 16C13.5 14.6167 13.9875 13.4375 14.9625 12.4625C15.9375 11.4875 17.1167 11 18.5 11C19.8833 11 21.0625 11.4875 22.0375 12.4625C23.0125 13.4375 23.5 14.6167 23.5 16C23.5 17.3833 23.0125 18.5625 22.0375 19.5375C21.0625 20.5125 19.8833 21 18.5 21ZM4.5 17V8C4.5 7.68333 4.57083 7.38333 4.7125 7.1C4.85417 6.81667 5.05 6.58333 5.3 6.4L11.3 1.9C11.4833 1.76667 11.675 1.66667 11.875 1.6C12.075 1.53333 12.2833 1.5 12.5 1.5C12.7167 1.5 12.925 1.53333 13.125 1.6C13.325 1.66667 13.5167 1.76667 13.7 1.9L19.75 6.45C19.8667 6.53333 19.9542 6.64167 20.0125 6.775C20.0708 6.90833 20.1 7.05 20.1 7.2C20.1 7.48333 20.0042 7.72083 19.8125 7.9125C19.6208 8.10417 19.3833 8.2 19.1 8.2C18.9833 8.2 18.875 8.18333 18.775 8.15C18.675 8.11667 18.5833 8.06667 18.5 8L12.5 3.5L6.5 8V17H10.5C10.7833 17 11.0208 17.0958 11.2125 17.2875C11.4042 17.4792 11.5 17.7167 11.5 18C11.5 18.2833 11.4042 18.5208 11.2125 18.7125C11.0208 18.9042 10.7833 19 10.5 19H6.5C5.95 19 5.47917 18.8042 5.0875 18.4125C4.69583 18.0208 4.5 17.55 4.5 17Z"
            />
        </svg>
    );
}