import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

type Props = {
    children: ReactNode;
    className?: string;
    style?: object;
};

export function Container({children, className = '', style = {}}: Props) {
    const props: Record<string, string|object> = {
        className: twMerge(
            "tw-w-full tw-max-w-[680px] tw-mx-auto tw-px-2 app-container",
            className
        )
    };
    if (style) {
        props.style = style;
    }
    return (
        <section {...props}>
            {children}
        </section>
    );
}
