export function formatCurrency(value: number): string {
    const rounded = Math.ceil(value);

    return Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        currencyDisplay: 'narrowSymbol',
    })
        .format(rounded);
}

export function intersect(options: string[], value: undefined|string|string[]): boolean {
    if (!value) {
        return false;
    }
    if (Array.isArray(value)) {
        return options.filter(v => value.includes(v)).length > 0;
    }

    return options.includes(value);
}
