'use client';
import {useSearchParams} from "next/navigation";
import {CreateType} from "@/utils/CreateType";
import {useTranslation} from "react-i18next";
import {defaultLanguage} from "@/i18n";
import {Lang} from "@/types";

function useQuery(): URLSearchParams {
    const params = useSearchParams();
    if (!params) {
        return new URLSearchParams();
    }
    return params;
}

export function useSearchValues(): CreateType | undefined {
    const query = useQuery();
    const params = query.get('search_params');

    return params ? JSON.parse(atob(params)) : undefined;
}

export function useLanguage(): Lang {
    const {i18n} = useTranslation();
    if (i18n.resolvedLanguage) {
      return i18n.resolvedLanguage as Lang;
    }
    return defaultLanguage;
}
