import { cn } from '@/utils/cn';

export function FenceIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 17V5L4.25 2.5L6.5 5V17H2ZM7.5 17V5L10 2.5L12.5 5V17H7.5ZM13.5 17V5L15.75 2.5L18 5V17H13.5Z" />
    </svg>
  );
}
