// Base on https://blog.stackademic.com/easy-way-to-add-translation-in-next-js-13-app-router-using-i18next-13b0663979c0
'use client';
import {memo, PropsWithChildren} from 'react';
import { I18nextProvider } from 'react-i18next';
import { createInstance } from 'i18next';
import initTranslations from '@/i18n';
import type {Resource} from "i18next";

interface TranslationProviderProps {
    locale: string;
    namespaces: string[];
    resources: Resource;
}

function createTranslationProvider({ children, locale, namespaces, resources }: PropsWithChildren<TranslationProviderProps>) {
    const i18n = createInstance();
    initTranslations(locale, namespaces, i18n, resources).catch();

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export const TranslationProvider = memo(createTranslationProvider);
