import {cn} from "@/utils/cn";

export function RestoreIcon({className = ''}: { className?: string }) {
    return (
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={cn("tw-inline-block tw-fill-current", className)}
        >
            <path
                d="M9.825 20.2975C8.10833 19.8141 6.70833 18.8683 5.625 17.46C4.54167 16.0516 4 14.4308 4 12.5975C4 11.6475 4.15833 10.7433 4.475 9.88496C4.79167 9.02663 5.24167 8.23913 5.825 7.52246C6.00833 7.32246 6.23333 7.21829 6.5 7.20996C6.76667 7.20163 7.00833 7.30579 7.225 7.52246C7.40833 7.70579 7.50417 7.93079 7.5125 8.19746C7.52083 8.46413 7.43333 8.71413 7.25 8.94746C6.85 9.46413 6.54167 10.0308 6.325 10.6475C6.10833 11.2641 6 11.9141 6 12.5975C6 13.9475 6.39583 15.1516 7.1875 16.21C7.97917 17.2683 9 17.9891 10.25 18.3725C10.4667 18.4391 10.6458 18.5641 10.7875 18.7475C10.9292 18.9308 11 19.1308 11 19.3475C11 19.6808 10.8833 19.9433 10.65 20.135C10.4167 20.3266 10.1417 20.3808 9.825 20.2975ZM14.175 20.2975C13.8583 20.3808 13.5833 20.3225 13.35 20.1225C13.1167 19.9225 13 19.6558 13 19.3225C13 19.1225 13.0708 18.9308 13.2125 18.7475C13.3542 18.5641 13.5333 18.4391 13.75 18.3725C15 17.9725 16.0208 17.2475 16.8125 16.1975C17.6042 15.1475 18 13.9475 18 12.5975C18 10.9308 17.4167 9.51413 16.25 8.34746C15.0833 7.18079 13.6667 6.59746 12 6.59746H11.925L12.325 6.99746C12.5083 7.18079 12.6 7.41413 12.6 7.69746C12.6 7.98079 12.5083 8.21413 12.325 8.39746C12.1417 8.58079 11.9083 8.67246 11.625 8.67246C11.3417 8.67246 11.1083 8.58079 10.925 8.39746L8.825 6.29746C8.725 6.19746 8.65417 6.08913 8.6125 5.97246C8.57083 5.85579 8.55 5.73079 8.55 5.59746C8.55 5.46413 8.57083 5.33913 8.6125 5.22246C8.65417 5.10579 8.725 4.99746 8.825 4.89746L10.925 2.79746C11.1083 2.61413 11.3417 2.52246 11.625 2.52246C11.9083 2.52246 12.1417 2.61413 12.325 2.79746C12.5083 2.98079 12.6 3.21413 12.6 3.49746C12.6 3.78079 12.5083 4.01413 12.325 4.19746L11.925 4.59746H12C14.2333 4.59746 16.125 5.37246 17.675 6.92246C19.225 8.47246 20 10.3641 20 12.5975C20 14.4141 19.4583 16.0308 18.375 17.4475C17.2917 18.8641 15.8917 19.8141 14.175 20.2975Z"
            />
        </svg>
    );
}