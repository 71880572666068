import {Container, LanguageSelector} from '@/components';
import {Icons} from "@/media";
import Image from "next/image";
import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

export function Header() {
    return (
        <header id="app-header" className="tw-z-[1000] tw-bg-white tw-shadow-2 tw-sticky tw-top-0 tw-min-h-16">
            <Container
                className="tw-flex tw-flex-row tw-no-warp tw-h-full tw-justify-around tw-items-stretch tw-min-h-16">
                <HeaderSection className="tw-justify-start tw-pl-2 tw-w-1/4">
                    <LanguageSelector/>
                </HeaderSection>
                <HeaderSection className="tw-justify-center tw-w-1/2">
                    <Image src={Icons.Logo} className="tw-pointer-events-none" alt="" priority={true}/>
                </HeaderSection>
                <HeaderSection id='app-header-actions' className="tw-justify-end tw-pr-2 tw-w-1/4"/>
            </Container>
        </header>
    );
}

function HeaderSection({children, className, id = ''}: { children?: ReactNode; className: string; id?: string }) {
    const classValue = twMerge(
        "tw-flex tw-flex-row tw-no-warp tw-items-center",
        className
    );
    const attr: Record<string, string> = {
        className: classValue
    };
    if (id) {
        attr.id = id;
    }
    return (
        <div {...attr}>
            {children}
        </div>
    );
}
