import {twMerge} from "@/tailwind-merge";

export function EditIcon({className = ''}: { className?: string }) {
    return (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={twMerge("fill-current tw-inline-block", className)}>
            <path
                d="M4.5 15.5H5.5625L13.375 7.6875L12.3125 6.625L4.5 14.4375V15.5ZM3.75375 17C3.54292 17 3.36458 16.9285 3.21875 16.7854C3.07292 16.6425 3 16.4653 3 16.2537V14.4444C3 14.2453 3.03472 14.0556 3.10417 13.875C3.17361 13.6944 3.28472 13.5278 3.4375 13.375L13.375 3.4375C13.5278 3.28472 13.6933 3.17361 13.8717 3.10417C14.0499 3.03472 14.2374 3 14.4342 3C14.6308 3 14.8194 3.03472 15 3.10417C15.1806 3.17361 15.3472 3.28472 15.5 3.4375L16.5625 4.5C16.7153 4.65278 16.8264 4.81944 16.8958 5C16.9653 5.18056 17 5.36486 17 5.55292C17 5.75361 16.9651 5.94486 16.8954 6.12667C16.8257 6.30847 16.7147 6.47458 16.5625 6.625L6.625 16.5625C6.47222 16.7153 6.3059 16.8264 6.12604 16.8958C5.94618 16.9653 5.75701 17 5.55854 17H3.75375ZM12.8344 7.16563L12.3125 6.625L13.375 7.6875L12.8344 7.16563Z"
                />
        </svg>
    );
}
