import { cn } from '@/utils/cn';

export function PonyToyIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-inline-block', className)}
    >
      <path d="M8 12.5C9.05556 12.5 10.0451 12.309 10.9688 11.9271C11.8924 11.5451 12.7153 11.0208 13.4375 10.3542L12.4583 9.20833C12.3333 9.31944 12.2083 9.42708 12.0833 9.53125C11.9583 9.63542 11.8264 9.73611 11.6875 9.83333L11 8.64583V7H12V6H7L6 4H3.5L4.25 4.54167L2.75 6.25L3.5 7L5 6.5V8.66667L4.3125 9.83333C4.17361 9.73611 4.04167 9.63542 3.91667 9.53125C3.79167 9.42708 3.66667 9.31944 3.54167 9.20833L2.5625 10.3542C3.27083 11.0208 4.09028 11.5451 5.02083 11.9271C5.95139 12.309 6.94444 12.5 8 12.5ZM8 11C7.58333 11 7.17708 10.9618 6.78125 10.8854C6.38542 10.809 6.00694 10.6944 5.64583 10.5417L6.39583 9.22917C6.64583 9.3125 6.90625 9.37847 7.17708 9.42708C7.44792 9.47569 7.72222 9.5 8 9.5C8.27778 9.5 8.55208 9.47569 8.82292 9.42708C9.09375 9.37847 9.35417 9.3125 9.60417 9.22917L10.3542 10.5417C9.99306 10.6944 9.61458 10.809 9.21875 10.8854C8.82292 10.9618 8.41667 11 8 11ZM1.5 16C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V1.5C0 1.08333 0.145833 0.729167 0.4375 0.4375C0.729167 0.145833 1.08333 0 1.5 0H14.5C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V14.5C16 14.9167 15.8542 15.2708 15.5625 15.5625C15.2708 15.8542 14.9167 16 14.5 16H1.5Z" />
    </svg>
  );
}
