import { cn } from '@/utils/cn';

export function LocationInBoxIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 17C4.09722 17 3.74653 16.8507 3.44792 16.5521C3.14931 16.2535 3 15.9028 3 15.5V4.5C3 4.0875 3.14931 3.73438 3.44792 3.44063C3.74653 3.14688 4.09722 3 4.5 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V15.5C17 15.9028 16.8531 16.2535 16.5594 16.5521C16.2656 16.8507 15.9125 17 15.5 17H4.5ZM10.01 5.41667C9.045 5.41667 8.19097 5.75694 7.44792 6.4375C6.70486 7.11806 6.33333 8.02778 6.33333 9.16667C6.33333 10.0957 6.70139 11.0119 7.4375 11.9154C8.17361 12.819 8.86111 13.5556 9.5 14.125C9.56944 14.1944 9.64583 14.2431 9.72917 14.2708C9.8125 14.2986 9.90278 14.3125 10 14.3125C10.0972 14.3125 10.1875 14.2986 10.2708 14.2708C10.3542 14.2431 10.4306 14.1944 10.5 14.125C11.1389 13.5556 11.8264 12.819 12.5625 11.9154C13.2986 11.0119 13.6667 10.0957 13.6667 9.16667C13.6667 8.02778 13.2985 7.11806 12.5621 6.4375C11.8257 5.75694 10.975 5.41667 10.01 5.41667ZM10.0023 10.1875C9.72299 10.1875 9.48958 10.091 9.30208 9.89812C9.11458 9.70535 9.02083 9.46924 9.02083 9.18979C9.02083 8.91049 9.11583 8.67708 9.30583 8.48958C9.49597 8.30208 9.72861 8.20833 10.0037 8.20833C10.279 8.20833 10.5139 8.30333 10.7083 8.49333C10.9028 8.68347 11 8.91611 11 9.19125C11 9.46653 10.9035 9.70139 10.7106 9.89583C10.5178 10.0903 10.2817 10.1875 10.0023 10.1875Z" />
    </svg>
  );
}
