import {ReactNode} from "react";
import {twMerge} from "@/tailwind-merge";

type Props = {
    children: ReactNode;
    className?: string;
};

export function CardText({children, className = ''}: Props) {
    return (
        <div className={twMerge("tw-text-gunmetal-700", className)}>{children}</div>
    );
}
