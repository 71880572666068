'use client';
import {YandexMetricaProvider} from 'next-yandex-metrica';
import {CONFIG} from "@/config";
import {ReactNode} from "react";

export function YandexMetrica({children}: { children: ReactNode }) {
    return (
        <YandexMetricaProvider
            tagID={CONFIG.YANDEX_METRIKA_ID}
            initParameters={{clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}}>
            {children}
        </YandexMetricaProvider>
    );
}
