export function HeartIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`tw-inline-block ${className}`}
    >
      <path d="M10 16.4583C9.81944 16.4583 9.64236 16.4271 9.46875 16.3646C9.29514 16.3021 9.13194 16.2014 8.97917 16.0625L7.75 14.9375C6.23611 13.5903 4.89931 12.2743 3.73958 10.9896C2.57986 9.70486 2 8.30556 2 6.79167C2 5.56917 2.41563 4.54826 3.24688 3.72896C4.07813 2.90965 5.11389 2.5 6.35417 2.5C7.06528 2.5 7.73611 2.64931 8.36667 2.94792C8.99722 3.24653 9.54167 3.67361 10 4.22917C10.4861 3.67361 11.0393 3.24653 11.6596 2.94792C12.2799 2.64931 12.9397 2.5 13.6392 2.5C14.8797 2.5 15.9167 2.90965 16.75 3.72896C17.5833 4.54826 18 5.56917 18 6.79167C18 8.30556 17.4201 9.70486 16.2604 10.9896C15.1007 12.2743 13.7639 13.5903 12.25 14.9375L11.0208 16.0625C10.8681 16.2014 10.7049 16.3021 10.5312 16.3646C10.3576 16.4271 10.1806 16.4583 10 16.4583ZM9.29167 5.79167C8.95833 5.22222 8.54167 4.78125 8.04167 4.46875C7.54167 4.15625 6.97917 4 6.35417 4C5.53875 4 4.85917 4.26389 4.31542 4.79167C3.77181 5.31944 3.5 5.98882 3.5 6.79979C3.5 7.50271 3.75 8.24306 4.25 9.02083C4.75 9.79861 5.34722 10.559 6.04167 11.3021C6.73611 12.0451 7.45139 12.7361 8.1875 13.375C8.92361 14.0139 9.52778 14.5417 10 14.9583C10.4722 14.5417 11.0764 14.0139 11.8125 13.375C12.5486 12.7361 13.2639 12.0451 13.9583 11.3021C14.6528 10.559 15.25 9.79861 15.75 9.02083C16.25 8.24306 16.5 7.50271 16.5 6.79979C16.5 5.98882 16.2282 5.31944 15.6846 4.79167C15.1408 4.26389 14.4613 4 13.6458 4C13.0208 4 12.4549 4.15625 11.9479 4.46875C11.441 4.78125 11.0208 5.22222 10.6875 5.79167C10.6181 5.93056 10.5205 6.03125 10.3948 6.09375C10.2691 6.15625 10.1372 6.1875 9.99896 6.1875C9.86076 6.1875 9.72569 6.15625 9.59375 6.09375C9.46181 6.03125 9.36111 5.93056 9.29167 5.79167Z" />
    </svg>
  );
}
