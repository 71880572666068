import { cn } from '@/utils/cn';

export function TreeIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={cn('tw-inline-block', className)}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 18V16.5H9.25V13H7.5C6.39333 13 5.45 12.61 4.67 11.83C3.89 11.05 3.5 10.1067 3.5 9C3.5 8.16667 3.72917 7.40972 4.1875 6.72917C4.64583 6.04861 5.27083 5.5625 6.0625 5.27083C6.24306 4.3125 6.70139 3.52778 7.4375 2.91667C8.17361 2.30556 9.02778 2 10 2C10.9722 2 11.8299 2.30556 12.5729 2.91667C13.316 3.52778 13.7708 4.3125 13.9375 5.27083C14.7292 5.54861 15.3542 6.03125 15.8125 6.71875C16.2708 7.40625 16.5 8.16667 16.5 9C16.5 10.1067 16.11 11.05 15.33 11.83C14.55 12.61 13.6067 13 12.5 13H10.75V16.5H16V18H4Z" />
    </svg>
  );
}
